<template>
    <a-card style="background-color: #fff;">
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row style="display:flex;align-items: center">
                        <div style="display:flex;align-items: center">
                            <span>限售区域名称：</span>
                            <a-input style="width:150px;" v-model="queryData.name"></a-input>        
                        </div>
                        <a-button @click="search" style="margin-left:10px">搜索</a-button>
                        <a-button @click="reset"  style="margin-left:10px">刷新</a-button>
                        <a-button style="margin-left: 10px;" type="primary"
                            @click="showModal()">添加模板</a-button>
                    </a-row>
                    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
                    queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                <div slot="createTime" slot-scope="{text}">
                    {{ formatDate(text) }}
                </div>
                <div slot="codeName" slot-scope="{text}"  class="ellipsis-container">
                    <span v-for="(item,index) in text" :key="index">{{item}},</span>    
                </div>
                
                <div slot="action" slot-scope="{text,record}">
                    <a @click="showModal(record)">编辑</a>
                </div>
            </standard-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <a-modal :width="1000" :maskClosable="false" :title="formData.id ? '编辑' : '新增'" :visible="isModalVisible"
            @cancel="handleCancel" @ok="handleSubmit" :confirm-loading="confirmLoading">
            <a-form-model ref="ruleForm" :model="formData"  :label-col="12"
                :wrapper-col="24">
                <a-form-model-item label="限售区域名称" required>
                    <a-input v-model="formData.name" placeholder="请输入限售区域名称" />
                </a-form-model-item>
                <!-- <a-form-model-item label="状态" required>
                    <a-select v-model="formData.status" placeholder="请选择状态" option-label-prop="label">
                        <a-select-option v-for="item in [{name:'上架',id:1},{name:'下架',id:2}]" :key="item.id" :value="item.id" :label="item.name">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item> -->
                <a-form-model-item label="限售区域" required>
                    <a-tree v-model="formData.code" checkable :expanded-keys="expandedKeys"
                        :auto-expand-parent="autoExpandParent" :selected-keys="selectedKeys" :tree-data="treeData"
                        @expand="onExpand" @select="onSelect" />
                </a-form-model-item>
            </a-form-model>
        </a-modal>



    </a-card>
</template>

<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '限售区域名称', dataIndex: 'name', align: 'center' },
    { title: '地区', dataIndex: 'codeName', align: 'center' ,width:'30%',ellipsis: true, scopedSlots: { customRender: 'codeName' }},
    // { title: '状态', dataIndex: 'statusName', align: 'center' },
    { title: '创建时间', dataIndex: 'createTime', align: 'center', scopedSlots: { customRender: 'createTime' } },
    { title: '操作', align: 'center', scopedSlots: { customRender: 'action' } },
]

export default {
    name: 'QueryList',
    components: { StandardTable },
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            searchProv: [],
            searchCity: [],
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {
                name: ''
                // 根据具体需要添加更多字段
            },
            form: this.$form.createForm(this),// Ant Design Vue 的表单对象
            treeData: [],
            expandedKeys: ['0-0-0', '0-0-1'],
            autoExpandParent: true,
            checkedKeys: ['0-0-0'],
            selectedKeys: [],
            city: []

        }
    },
    mounted() {
        this.getData()
        this.getCity()
    },
    watch: {
        checkedKeys(val) {
            console.log('onCheck', val);
        },
    },
    methods: {
        buildTree(data) {
            // 创建一个 id 到节点的映射
            const map = new Map();
            // 初始化映射
            data.forEach(item => {
                map.set(item.id, {
                    ...item,
                    title: item.name,  // 新增 title 属性，值为 name
                    key: item.id,      // 新增 key 属性，值为 id
                    children: []       // 初始化 children 属性为空数组
                });
            });
            // 用于保存根节点的数组
            const tree = [];
            // 构建树
            data.forEach(item => {
                if (item.pId === 0) {
                    // 如果 pId 是 0，说明是根节点
                    tree.push(map.get(item.id));
                } else {
                    // 找到父节点并将当前节点添加到其 children 数组中
                    const parent = map.get(item.pId);
                    if (parent) {
                        parent.children.push(map.get(item.id));
                    }
                }
            });

            return tree;
        },
        getCity() {
            request(process.env.VUE_APP_API_BASE_URL + 'srm/adcode/list', 'get').then(res => {
                this.treeData = this.buildTree(res.data.data);
            })
        },
        onExpand(expandedKeys) {
            console.log('onExpand', expandedKeys);
            this.expandedKeys = expandedKeys;
            this.autoExpandParent = false;
        },
        onCheck(checkedKeys) {
            console.log('onCheck', checkedKeys);
            this.checkedKeys = checkedKeys;
        },
        onSelect(selectedKeys, info) {
            console.log('onSelect', info);
            this.selectedKeys = selectedKeys;
        },
        showModal(record) {
            this.confirmLoading = true;
            if(record) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/delivery/scope/'+record.id, 'get').then(res => {
                    console.log(res,'res')
                    this.confirmLoading = false;
                    this.selectedKeys = res.data.data.code;
                    this.formData = res.data.data;
                    // this.treeData = this.buildTree(res.data.data);
                })
            } else {
                this.confirmLoading = false;
                this.formData = {
                    name:'',
                    code:null,
                    status:null
                }
                this.selectedKeys = []
            }
            this.isModalVisible = true;
        },
        handleCancel() {
            this.isModalVisible = false; // 取消操作
        },
        handleSubmit(){
            if(!this.formData.name) {
                this.$message.error('请输入限售区域名称！')
                return false;
            }

            // if(!this.formData.status) {
            //     this.$message.error('请选择状态！')
            //     return false;
            // }
            if(!this.formData.code) {
                this.$message.error('请选择区域！')
                return false;
            }
            this.formData.status = 1;
            if (this.formData.id) {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/delivery/scope/'+this.formData.id, 'post', this.formData).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('更新成功！')
                        this.isModalVisible = false;
                    }
                })
            } else {
                request(process.env.VUE_APP_API_BASE_URL + 'srm/delivery/scope', 'post', this.formData).then(res => {
                    if (res.data.code == 200) {
                        this.getData();
                        this.$message.success('保存成功！')
                        this.isModalVisible = false;
                    }
                })
            }
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                name:""
            }
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'srm/delivery/scope/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
.ellipsis-container {
  white-space: nowrap; /* 不允许换行 */
  overflow: hidden; /* 超出的内容隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  display: block;
}
</style>